<template>
    <div class="card">
        <h5>Nossas Unidades</h5>

        <DataTable ref="estabs" :value="contentList" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true"
					v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack"
					:globalFilterFields="['nome','telefone','endereco','cidade','estado']" 
                    :sortField="sortField" :sortOrder="sortOrder" >
                <template #header>
                    <div class="flex justify-content-between flex-column sm:flex-row">
                        <Button type="button" 
                                icon="pi pi-filter-slash" 
                                label="Limpar" 
                                class="p-button-outlined mb-2" 
                                @click="clearFilter1()" />
                        <span class="p-input-icon-left mb-2">
                            <i class="pi pi-search" />
                            <InputText v-model="filters1['global'].value" 
                                    placeholder="Nome, Telefone, Endereço, Cidade, Estado" 
                                    style="width: 315px;" />
                        </span>
                    </div>
                </template>
            <template #empty>
               Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando dados. Por favor, aguarde!
            </template>
            <Column field="nome" header="Nome" style="width: 25%;" sortable>
                <template #body="data">
					{{data.data.nome}}
				</template>
            </Column>         
            <Column field="telefone" header="Telefone" style="width: 15%;" sortable>
                <template #body="data">
					{{data.data.telefone}}
				</template>
            </Column>
            <Column field="endereco" header="Endereço" style="width: 35%;" sortable>
                <template #body="data">
					{{data.data.endereco}}
				</template>
            </Column>
            <Column field="cidade" header="Cidade" style="width: 15%;" sortable>
                <template #body="data">
					{{data.data.cidade}}
				</template>
            </Column>
            <Column field="estado" header="Estado" style="width: 10%;" sortable>
                <template #body="data">
					{{data.data.estado}}
				</template>
            </Column>
        </DataTable>
    </div>     
</template>

<script >
    import EstabelecimentoService from '../../service/EstabelecimentoService'
    import {FilterMatchMode,FilterOperator} from 'primevue/api';

    export default {
        name: 'Estabelecimentos',      
        data() {
            return {
                contentList: [],
                filters1: null,
				loading1: true,
				loading2: true, 
                sortField: '',
                sortOrder: 1,
            }
        },

		created() {
			this.getEstabelecimentos();
            this.initFilters1();
		},
        methods: {
           getEstabelecimentos() {
                EstabelecimentoService.getEstabelecimentos().then((response) => {
                    this.contentList = response.data;
                    this.loading1 = false;
                    this.loading2 = false;
                }) ;
            },
            initFilters1() {
				this.filters1 = {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'nome': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'telefone': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'endereco': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'cidade': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'estado': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				}
			}, 
            clearFilter1() {
                this.initFilters1();
                this.contentList = [];
                this.sortField = 'estado';
                this.loading1 = true;
                this.loading2 = true;
                
                setTimeout(() => {
                    this.getEstabelecimentos();
                    this.sortField = null;
                }, 250);
			},
            
            formatCurrency(value) {
				return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
			},            
        }     
       
    }
</script>