<template>
    <Estabelecimento />
</template>

<script lang="js">
    
    
    import Estabelecimento from '../../components/consulta/Estabelecimento.vue'

    export  default {
        components: { Estabelecimento }
    }

</script>